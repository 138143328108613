<template>
  <div class="user">
    <!-- 标题栏 -->
    <HeaderCon ></HeaderCon>
    <div class="user_main">
      <div class="sider">
        <div
          :class="[current == item.name ? 'sider_item active' : 'sider_item ']"
          v-for="item in siderArr"
          :key="item.name"
          @click="changepage(item.name)"
        >
          <img
            :src="current == item.name ? item.img_select : item.img"
            alt=""
            class="sider_item_img"
          />
          <div
            :class="[
              current == item.name
                ? ' sider_item_name active '
                : 'sider_item_name',
            ]"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import HeaderCon from "../../components/HeaderCon.vue";
export default {
  name: "adminset",
  components: {
    HeaderCon,
  },
  data() {
    return {
      current: "用户信息管理",
      username: "",
      siderArr: [
        {
          name: "用户信息管理",
          img: require("@/static/user/icon-grxx.png"),
          img_select: require("@/static/user/icon-grxx-select.png"),
        },
      ],
    };
  },
  methods: {
    changepage(name) {
      switch (name) {
        case "用户信息管理":
          this.$router.push({
            path: "/userInfo",
          });
          break;
      }
      this.current = name;
    },
  },
};
</script>
<style lang="less" scoped>
.user {
  width: calc(100%);
  height: 100%;
  // min-height: 900px;
  .user_main {
    height: 100%;
    min-height: 900px;
    // height: 100%;
    width: 100%;
    background-color: #ebebeb;
    display: flex;
    .home_con {
      background-color: #ebebeb;
      padding-left: 30px;
      width: calc(100% -80px);
      flex: 1;
      .nav_bread {
        height: 50px;
        padding: 20px 3px;
        background-color: #ebebeb;
      }
    }
  }
  .sider {
    // height: calc(100% - 70px);
    background-color: #002d70;
    width: 80px;
    .sider_item {
      height: 100px;
      text-align: center;
      padding: 20px 10px 20px 0;
      .sider_item_img {
        width: 27px;
      }
      .sider_item_name {
        margin-top: 10px;
        color: #848bad;
        font-size: 14px;
        text-align: center;
      }
      .active {
        color: #fff;
      }
    }
    .active {
      background-color: #124795;
      color: #fff;
    }
  }
}
</style>
