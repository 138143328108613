<template>
  <div class="center_left_echarts">
    <div class="center_left_title">
      <span>专利受让省份排名</span>
      <div class="center_left_title_btn">
        <span :class="[type1 == 1 ? 'active' : '']" @click="changeType(1)"
          >专利总量</span
        >
        <span :class="[type1 == 2 ? 'active' : '']" @click="changeType(2)"
          >发明专利</span
        >
        <span :class="[type1 == 3 ? 'active' : '']" @click="changeType(3)"
          >实用新型</span
        >
        <span :class="[type1 == 4 ? 'active' : '']" @click="changeType(4)"
          >外观设计</span
        >
        <a-select
          ref="select"
          v-model:value="yearValue"
          @change="handleChange"
          :options="yearOptions"
        >
        </a-select>
      </div>
    </div>
    <div class="echarts_container">
      <EchartsCon
        :id="'province'"
        :option="option1"
        v-if="option1"
      ></EchartsCon>
    </div>
  </div>
  <div class="center_left_echarts">
    <div class="center_left_title">
      <span>专利发明人排名</span>
      <div class="center_left_title_btn">
        <span :class="[type2 == 1 ? 'active' : '']" @click="changeAuth(1)"
          >专利总量</span
        >
        <span :class="[type2 == 2 ? 'active' : '']" @click="changeAuth(2)"
          >发明专利</span
        >
        <span :class="[type2 == 3 ? 'active' : '']" @click="changeAuth(3)"
          >实用新型</span
        >
        <span :class="[type2 == 4 ? 'active' : '']" @click="changeAuth(4)"
          >外观设计</span
        >
        <a-select
          ref="select"
          v-model:value="yearValue1"
          @change="changeYear"
          :options="yearOptions"
        >
        </a-select>
      </div>
    </div>
    <div class="echarts_container">
      <EchartsCon
        :id="'rank'"
        :option="option2"
        v-if="option2"
      ></EchartsCon>
    </div>
  </div>
</template>
<script>
import EchartsCon from "@/components/EchartsCon.vue";
import { patentSSProvince, patentInventor } from "@/utils/screen.js";
import { message } from "ant-design-vue";

export default {
  name: "patentPerson",
  data() {
    return {
      yearOptions: [
        {
          value: 1,
          label: "今年",
        },
        {
          value: 2,
          label: "近两年",
        },
        {
          value: 3,
          label: "近三年",
        },
        {
          value: 4,
          label: "近四年",
        },
        {
          value: 5,
          label: "近五年",
        },
        {
          value: 6,
          label: "近六年",
        },
        {
          value: 7,
          label: "近七年",
        },
        {
          value: 8,
          label: "近八年",
        },
        {
          value: 9,
          label: "近九年",
        },
        {
          value: 10,
          label: "近十年",
        },
      ],
      yearValue: 3,
      option1: null,
      option2: null,
      type1: 1,
      type2: 1,
      options1: [],
      yearValue1: 3,
    };
  },
  components: {
    EchartsCon,
  },
  methods: {
    init() {
      //近十年年份数据
      let date = new Date();
      let years = Array.from(
        { length: 10 },
        (v, i) => date.getFullYear() - 10 + i + 1
      ).reverse();
      years.forEach((item) => {
        this.options1.push({
          label: item,
          value: item,
        });
      });
      this.getTypeData();
      this.getCompareData();
    },
    //获取专利类型数据
    getTypeData() {
      let data = [];
      patentSSProvince({ type: this.type1, year: this.yearValue }).then(
        (res) => {
          if (res.code == 0 && res.data.length > 0) {
            data = [...res.data];
            this.getOption(data);
          } else if (res.code == 0 && res.data.length == 0) {
            data = [];
            this.getOption(data);
          } else {
            message.error(res.msg);
          }
        }
      );
    },
    //修改专利受让省份排名时间
    handleChange() {
      this.getTypeData();
    },
    //修改专利发明人排名时间
    changeYear(){
      this.getCompareData();
    },

    //获取专利发明人排名
    getCompareData() {
      let data = {
        xData: [],
        yData: [],
      };
      patentInventor({
        type: this.type2,
        year: this.yearValue1,
        page: 1,
        size: 20,
      }).then((res) => {
        res.data.list.forEach((item) => {
          data.xData.push(item.name);
          data.yData.push(item.value);
        });

        this.getCompareOption(data);
      });
    },
    getOption(data) {
      // const colorList = ["#1E78FF", "#E94C5B", "#2EC59A"];
      this.option1 = {
        tooltip: {
          show: true,
        },
        legend: {
          top: "center",
          left: "10%",
          orient: "vertical",
          icon: "circle",
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 8,

          textStyle: {
            color: "#fff",
            fontFamily: "jiangcheng",
          },
        },
        series: [
          {
            type: "pie",
            center: ["50%", "50%"],
            radius: ["30%", "70%"],
            label: {
              show: true,
              position: "inside",
              color: "#fff",
              formatter: "{d}%",
            },
            labelLine: {
              show: false,
            },
            itemStyle: {
              normal: {
                // color: (params) => {
                //   return colorList[params.dataIndex];
                // },
              },
              shadowColor: "rgba(0, 0, 0, 0.5)",
              shadowBlur: 10,
            },
            // silent: true,
            data: data,
          },
        ],
      };
    },
    //专利发明人排名option
    getCompareOption(data) {
      this.option2 = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            textStyle: {
              color: "#fff",
            },
          },
          textStyle: {
            color: "#fff",
          },
          backgroundColor: "rgba(45, 67, 119, 1)", //设置背景颜色
        },
        grid: {
          left: "3%",
          right: "3%",
          bottom: "1%",
          top: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: data.xData,
          axisPointer: {
            type: "shadow",
          },
          axisLabel: {
            color: "rgba(255, 255, 255, .8)",
            interval: 0,
            rotate: 30,
            margin: 15,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(45, 67, 119, 0.8)",
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          // name: "(户)",
          nameTextStyle: {
            color: "#B5C5D4",
            fontSize: 14,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(45, 67, 119, 0.8)",
            },
          },
          min: 0,
          axisLabel: {
            show: true,
            color: "#B5C5D4",
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              // width: 0.5,
              color: "rgba(45, 67, 119, 1)",
              type: "dashed",
            },
          },
        },
        series: [
          {
            data: data.yData,
            // name: this.yearValue1,
            type: "bar",
            barWidth: "8px",
            itemStyle: {
              normal: {
                color: "rgba(68, 174, 248, 1)",
                borderRadius: [5, 5, 0, 0],
              },
            },
            label: {
              show: false,
              position: "top",
              fontSize: 12,
              color: "#F5F5F5",
              offset: [0, -5],
              formatter: "{c}",
            },
          },
        ],
        dataZoom: [
          {
            id: "dataZoomY",
            xAxisIndex: [0, 1],
            show: true, //是否显示滑动条，不影响使用
            type: "slider", // 这个 dataZoom 组件是 slider 型 dataZoom 组件
            orient: "horizontal",
            startValue: 0, // 从头开始。
            endValue: 9, // 一次性展示个
            height: 3,
            bottom:0,
            borderColor: "transparent",
            fillerColor: "rgba(205,205,205,1)",
            zoomLock: true,
            showDataShadow: false, //是否显示数据阴影 默认auto
            backgroundColor: "transparent",
            showDetail: false, //即拖拽时候是否显示详细数值信息 默认true
            realtime: true, //是否实时更新
            filterMode: "filter",
            handleIcon: "circle",
            handleStyle: {
              color: "rgba(205,205,205,1)",
              borderColor: "rgba(205,205,205,1)",
            },
            // handleSize: '80%',
            moveHandleSize: 0,
            brushSelect: false, //刷选功能，设为false可以防止拖动条长度改变 ************（这是一个坑）
          },
          {
            type: "inside",
            xAxisIndex: [0, 1],
            orient: "horizontal",
            zoomOnMouseWheel: false, //滚轮是否触发缩放
            moveOnMouseMove: true, //鼠标滚轮触发滚动
            moveOnMouseWheel: true,
            bottom:0,
          },
        ],
      };
    },
    //切换专利受让省份类型
    changeType(type) {
      this.type1 = type;
      this.getTypeData();
    },
    //切换专利发明人排名类型
    changeAuth(type) {
      this.type2 = type;
      this.getCompareData();
    },
  },
  beforeMount() {
    this.init();
  },
  mounted() {
    // this.getOption();
    // this.getCompareOption()
  },
};
</script>
<style lang="less" scoped>
.center_left_echarts {
  padding: 0 15px 0 5px;
  height: 45%;
  margin-bottom: 25px;
  .center_left_title {
    background: url("../../../static/screen/title-short.png") no-repeat;
    background-size: 100% 100%;
    font-size: 20px;
    height: 39px;
    width: 100%;
    font-family: "jiangcheng";
    font-weight: normal;
    font-style: italic;
    color: #f6f9fe;
    text-shadow: 0px 2px 8px rgba(5, 28, 55, 0.42);
    padding-left: 35px;
    padding-top: 6px;
    .center_left_title_btn {
      font-size: 14px;
      font-family: "jiangcheng";
      font-weight: normal;
      color: #bbcdef;
      opacity: 0.8;
      span {
        padding: 5px 10px;
        cursor: pointer;
      }
      .active {
        color: #fff;
        text-shadow: 0px 2px 8px #f6f9fe;
      }
      :deep(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
        background-color: #2d58b8;
        color: #f6f9fe;
        font-family: Arial, sans-serif;
      }
      :deep(.ant-select-dropdown) {
        background-color: #1052df !important;
      }
      :deep(.ant-select) {
        width: 90px !important;
        float: right;
      }
      :deep(
          .ant-select-single.ant-select-show-arrow .ant-select-selection-item
        ) {
        padding-right: 10px;
      }
    }
  }
  .echarts_container {
    // height: 120px;
    margin-top: 40px;
    height: 70%;
  }
}
</style>
