<template>
  <div :id="id" :style="style"></div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-wordcloud";
export default {
  name: "EchartsCloud",
  data() {
    return {
      chart: "",
    };
  },
  props: {
    id: {
      type: String,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "130%",
    },
    dataArr: {
      type: Array,
      default: [],
    },
  },
  computed: {
    style() {
      return {
        width: this.width,
        height: this.height,
      };
    },
  },
  watch: {
    option: {
      handler(newVal) {
        if (this.chart) {
          this.chart.setOption(newVal);
        } else {
          this.init();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.initchart();
  },
  methods: {
    initchart() {
      let myChart = echarts.init(document.getElementById(this.id));
      let color = [
        "#FF4040",
        "#FFA500",
        "#3CB371",
        "#8470FF",
        "#FA8072",
        "#EEA9B8",
        "#9F79EE",
        "#87CEFF",
      ];
      myChart.setOption({
        series: [
          {
            type: "wordCloud",
            //用来调整词之间的距离
            gridSize: 10,
            //用来调整字的大小范围
            // Text size range which the value in data will be mapped to.
            // Default to have minimum 12px and maximum 60px size.
            sizeRange: [14, 60],
            // Text rotation range and step in degree. Text will be rotated randomly in range [-90,                                                                             90] by rotationStep 45
            //用来调整词的旋转方向，，[0,0]--代表着没有角度，也就是词为水平方向，需要设置角度参考注释内容
            // rotationRange: [-45, 0, 45, 90],
            // rotationRange: [ 0,90],
            rotationRange: [0, 0],
            //随机生成字体颜色
            // maskImage: maskImage,

            textStyle: {
              color: function (params) {
                var index = params.dataIndex;
                return color[index % color.length];
              },
            },
            left: "center",
            top: "center",
            right: null,
            bottom: null,
            width: "100%",
            height: "100%",
            //数据
            data: this.dataArr,
          },
        ],
      });
      window.addEventListener("resize", myChart.resize);
    },
  },
};
</script>
