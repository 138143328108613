
const arealist = [
  {
    label: '太原市',
    value: '太原市',
    children: [{
      label: "小店区",
      value: "小店区"
    }, {
      label: "迎泽区",
      value: "迎泽区"
    }, {
      label: "杏花岭区",
      value: "杏花岭区"
    }, {
      label: "尖草坪区",
      value: "尖草坪区"
    }, {
      label: "万柏林区",
      value: "万柏林区"
    }, {
      label: "晋源区",
      value: "晋源区"
    }, {
      label: "清徐县",
      value: "清徐县"
    }, {
      label: "阳曲县",
      value: "阳曲县"
    }, {
      label: "娄烦县",
      value: "娄烦县"
    }, {
      label: "古交市",
      value: "古交市"
    }]
  },
  {
    label: "大同市",
    value: "大同市",
    children: [{
      label: "新荣区",
      value: "新荣区"
    }, {
      label: "平城区",
      value: "平城区"
    }, {
      label: "云冈区",
      value: "云冈区"
    }, {
      label: "云州区",
      value: "云州区"
    }, {
      label: "阳高县",
      value: "阳高县"
    }, {
      label: "天镇县",
      value: "天镇县"
    }, {
      label: "广灵县",
      value: "广灵县"
    }, {
      label: "灵丘县",
      value: "灵丘县"
    }, {
      label: "浑源县",
      value: "浑源县"
    }, {
      label: "左云县",
      value: "左云县"
    }]
  },
  {
    label: "阳泉市",
    value: "阳泉市",
    children: [{
      label: "城区",
      value: "城区"
    }, {
      label: "矿区",
      value: "矿区"
    }, {
      label: "郊区",
      value: "郊区"
    }, {
      label: "平定县",
      value: "平定县"
    }, {
      label: "盂县",
      value: "盂县"
    }]
  },
  {
    label: "长治市",
    value: "长治市",
    children: [{
      label: "潞州区",
      value: "潞州区"
    }, {
      label: "上党区",
      value: "上党区"
    }, {
      label: "屯留区",
      value: "屯留区"
    }, {
      label: "潞城区",
      value: "潞城区"
    }, {
      label: "襄垣县",
      value: "襄垣县"
    }, {
      label: "平顺县",
      value: "平顺县"
    }, {
      label: "黎城县",
      value: "黎城县"
    }, {
      label: "壶关县",
      value: "壶关县"
    }, {
      label: "长子县",
      value: "长子县"
    }, {
      label: "武乡县",
      value: "武乡县"
    }, {
      label: "沁县",
      value: "沁县"
    }, {
      label: "沁源县",
      value: "沁源县"
    }]
  },
  {
    label: "晋城市",
    value: "晋城市",
    children: [{
      label: "城区",
      value: "城区"
    }, {
      label: "沁水县",
      value: "沁水县"
    }, {
      label: "阳城县",
      value: "阳城县"
    }, {
      label: "陵川县",
      value: "陵川县"
    }, {
      label: "泽州县",
      value: "泽州县"
    }, {
      label: "高平市",
      value: "高平市"
    }]
  },
  {
    label: "朔州市",
    value: "朔州市",
    children: [{
      label: "朔城区",
      value: "朔城区"
    }, {
      label: "平鲁区",
      value: "平鲁区"
    }, {
      label: "山阴县",
      value: "山阴县"
    }, {
      label: "应县",
      value: "应县"
    }, {
      label: "右玉县",
      value: "右玉县"
    }, {
      label: "怀仁市",
      value: "怀仁市"
    }]
  },
  {
    label: "晋中市",
    value: "晋中市",
    children: [{
      label: "榆次区",
      value: "榆次区"
    }, {
      label: "太谷区",
      value: "太谷区"
    }, {
      label: "榆社县",
      value: "榆社县"
    }, {
      label: "左权县",
      value: "左权县"
    }, {
      label: "和顺县",
      value: "和顺县"
    }, {
      label: "昔阳县",
      value: "昔阳县"
    }, {
      label: "寿阳县",
      value: "寿阳县"
    }, {
      label: "祁县",
      value: "祁县"
    }, {
      label: "平遥县",
      value: "平遥县"
    }, {
      label: "灵石县",
      value: "灵石县"
    }, {
      label: "介休市",
      value: "介休市"
    }]
  },
  {
    label: "运城市",
    value: "运城市",
    children: [{
      label: "盐湖区",
      value: "盐湖区"
    }, {
      label: "临猗县",
      value: "临猗县"
    }, {
      label: "万荣县",
      value: "万荣县"
    }, {
      label: "闻喜县",
      value: "闻喜县"
    }, {
      label: "稷山县",
      value: "稷山县"
    }, {
      label: "新绛县",
      value: "新绛县"
    }, {
      label: "140826",
      value: "绛县"
    }, {
      label: "垣曲县",
      value: "垣曲县"
    }, {
      label: "夏县",
      value: "夏县"
    }, {
      label: "平陆县",
      value: "平陆县"
    }, {
      label: "芮城县",
      value: "芮城县"
    }, {
      label: "永济市",
      value: "永济市"
    }, {
      label: "河津市",
      value: "河津市"
    }]
  },
  {
    label: "忻州市",
    value: "忻州市",
    children: [{
      label: "忻府区",
      value: "忻府区"
    }, {
      label: "定襄县",
      value: "定襄县"
    }, {
      label: "五台县",
      value: "五台县"
    }, {
      label: "代县",
      value: "代县"
    }, {
      label: "繁峙县",
      value: "繁峙县"
    }, {
      label: "宁武县",
      value: "宁武县"
    }, {
      label: "静乐县",
      value: "静乐县"
    }, {
      label: "神池县",
      value: "神池县"
    }, {
      label: "五寨县",
      value: "五寨县"
    }, {
      label: "岢岚县",
      value: "岢岚县"
    }, {
      label: "河曲县",
      value: "河曲县"
    }, {
      label: "保德县",
      value: "保德县"
    }, {
      label: "偏关县",
      value: "偏关县"
    }, {
      label: "原平市",
      value: "原平市"
    }]
  },
  {
    label: "临汾市",
    value: "临汾市",
    children: [{
      label: "尧都区",
      value: "尧都区"
    }, {
      label: "曲沃县",
      value: "曲沃县"
    }, {
      label: "翼城县",
      value: "翼城县"
    }, {
      label: "襄汾县",
      value: "襄汾县"
    }, {
      label: "洪洞县",
      value: "洪洞县"
    }, {
      label: "古县",
      value: "古县"
    }, {
      label: "安泽县",
      value: "安泽县"
    }, {
      label: "浮山县",
      value: "浮山县"
    }, {
      label: "吉县",
      value: "吉县"
    }, {
      label: "乡宁县",
      value: "乡宁县"
    }, {
      label: "大宁县",
      value: "大宁县"
    }, {
      label: "隰县",
      value: "隰县"
    }, {
      label: "永和县",
      value: "永和县"
    }, {
      label: "蒲县",
      value: "蒲县"
    }, {
      label: "汾西县",
      value: "汾西县"
    }, {
      label: "侯马市",
      value: "侯马市"
    }, {
      label: "霍州市",
      value: "霍州市"
    }]
  },
  {
    label: "吕梁市",
    value: "吕梁市",
    children: [{
      label: "离石区",
      value: "离石区"
    }, {
      label: "文水县",
      value: "文水县"
    }, {
      label: "交城县",
      value: "交城县"
    }, {
      label: "兴县",
      value: "兴县"
    }, {
      label: "临县",
      value: "临县"
    }, {
      label: "柳林县",
      value: "柳林县"
    }, {
      label: "石楼县",
      value: "石楼县"
    }, {
      label: "岚县",
      value: "岚县"
    }, {
      label: "方山县",
      value: "方山县"
    }, {
      label: "中阳县",
      value: "中阳县"
    }, {
      label: "交口县",
      value: "交口县"
    }, {
      label: "孝义市",
      value: "孝义市"
    }, {
      label: "汾阳市",
      value: "汾阳市"
    }]
  }
]

export default arealist