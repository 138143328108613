<template>
  <div class="header">
    <div class="header_left">
      <img src="../static/login/logo-tylgd.png" alt="" />
      <span>太原理工大学专利成果转移转化智能推荐系统</span>
    </div>
    <div class="header_right">
      <!-- <img src="" alt=""> -->

      <a-popover>
        <template #content>
          <div v-if="!isadmin" class="portrait_btn" @mouseenter="hover($event, '1')" @mouseleave="leave($event)">
            <span v-if="hover_num == 1" @click="toset" style="cursor: pointer">
              <img src="@/static/common/icon-yhsz-hover.png" alt="" />&nbsp;
              <span style="color: #3e7bfa">用户设置</span>
            </span>
            <span v-else>
              <img src="@/static/common/icon-yhsz.png" alt="" />&nbsp;
              <span>用户设置</span>
            </span>
          </div>
          <br />
          <div class="portrait_btn" @mouseenter="hover($event, '2')" @mouseleave="leave($event)">
            <span v-if="hover_num == 2" @click="openModal" style="cursor: pointer">
              <img src="@/static/common/icon-tcdl-hover.png" alt="" />&nbsp;
              <span style="color: #3e7bfa">退出登录</span>
            </span>
            <span v-else>
              <img src="@/static/common/icon-tcdl.png" alt="" />&nbsp;
              <span>退出登录</span>
            </span>
          </div>
        </template>
        <img :src="img" class="portrait" v-if="img" />
        <img src="../static/common/img-tx-big.svg" class="portrait" v-else />
        <span class="tel">{{ username }}</span>
      </a-popover>
    </div>
    <!-- <a-modal
      v-model:visible="visible"
      title="退出登录"
      @ok="exit"
      cancelText="取消"
      okText="确定"
      @onCancel="closeModal"
    >
      
       
    </a-modal> -->
  </div>
</template>
<script>
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { createVNode } from "vue";
import { Modal } from "ant-design-vue";
import { userInfo } from "@/utils/userAPI";
export default {
  name: "HeaderCon",
  props: {},
  created() {
    // this.username = localStorage.getItem("login_name");
    this.getUserInfo();
  },
  computed: {},
  data() {
    return {
      hover_num: 0,
      username: "",
      isadmin: false,
      type: "专利",
      keyword: "",
      visible: false,
      img: "",
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    //获取用户信息
    getUserInfo() {
      userInfo().then((res) => {
        if (res.code == 0) {
          this.username = res.data.account_number;
          this.img = res.data.head_img;
          this.isadmin = res.data.is_admin;
        }
      });
    },
    hover(e, num) {
      // console.log(num)
      this.hover_num = num;
    },
    leave() {
      this.hover_num = 0;
    },
    toset() {
      let routeUrl = this.$router.resolve({
        path: "/usersetting",
      });
      window.open(routeUrl.href, "_blank");
    },
    //打开弹窗
    openModal() {
      let that = this;
      Modal.confirm({
        title: "确认退出登录吗?",
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          that.exit();
        },
        okText: "确认",
        onCancel() {},
        cancelText: "取消",
      });
    },
    //关闭弹窗
    closeModal() {
      this.visible = false;
    },
    exit() {
      this.$router.push({
        path: "/login",
      });
      localStorage.removeItem("token");
      // localStorage.removeItem("login_name");
      // localStorage.removeItem("password");
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  height: 65px;
  background-color: #fff;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  .header_left {
    padding: 10px 29px;
    span {
      color: #002d70;
      font-family: "jiangcheng";
      padding: 0 15px;
    }
  }
  // .header_con {
  //   //  padding: 0 10px 30px;
  //   width: 700px;
  //   height: 50px;
  //   margin: 10px;
  //   // padding: 10px;
  //   border: 2px solid #4079ff;
  //   // border-radius: 8px;
  //   .type {
  //     width: 160px;
  //     height: 40px;
  //     // margin-top: 10px;
  //     // line-height: 50px;
  //     padding: 0px 0;
  //     // background-color: #fff;
  //     border-color: transparent;
  //   }
  //   :deep(.ant-input-group .ant-input) {
  //     // height: 54px;
  //     font-size: 18px;
  //   }
  //   :deep(.ant-input) {
  //     border-color: transparent;
  //     padding-top: 10px;
  //   }
  //   :deep(.ant-input-group-addon:first-child) {
  //     border-color: transparent;
  //     padding-top: 5px;
  //     background-color: transparent !important;
  //   }
  //   :deep(.ant-input-group-addon:last-child) {
  //     height: 48px;
  //     width: 120px;
  //     background-color: #4079ff !important;
  //     color: #fff;
  //     font-size: 18px;
  //   }
  // }
  .header_right {
    padding: 0 30px 30px;
    // display: inline-block;
    line-height: 60px;
    .portrait {
      display: inline-block;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      // background-color: pink;
      // background: url("../static/common/img-tx-big.svg") no-repeat;
      background-size: 100% 100%;
      vertical-align: baseline;

      .portrait_btn {
        cursor: pointer !important;
        .active {
          color: #3e7bfa !important;
        }
      }
    }
    .portrait_set > .ant-popover-inner-content {
      cursor: pointer;
    }

    .tel {
      padding: 0 15px;
      color: #86909c;
      font-family: "Microsoft YaHei-Bold";
      line-height: 60px;
      display: inline-block;
      vertical-align: text-bottom;
    }
  }
}
:deep(.ant-input-group-addon) {
  background-color: transparent;
}
</style>
