import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import { message } from "ant-design-vue";
// import HomeView from '../views/HomeView.vue'
import login from "../views/login/index.vue"
import home from "../views/home/home.vue"
import index from "../views/home/index.vue"
//企业页面
import company from "../views/company/index.vue"
import companyDetails from "../views/company/companyDetails.vue"
//专利页面
import patent from "../views/patent/index.vue"
import patentDetails from "../views/patent/patentDetails.vue"
//历史记录
import history from "../views/history/index.vue"
//我的关注
import follow from "../views/follow/index.vue"
//管理员登录
import AdminLogin from "../views/login/admin.vue"
//忘记密码
import forget from "../views/login/forget.vue"
//个人设置
import usersetting from "../views/userset/index.vue"
import personalSetting from "../views/userset/personalSetting.vue"
import securitySetting from "../views/userset/securitySetting.vue"
//管理员设置
import adminset from "../views/adminset/index.vue"
import userInfo from "../views/adminset/userInfo.vue"
import screen from "../views/screen/index.vue"
import notFound from "../views/404.vue"


const routes = [
  {
    path: '/',
    redirect: '/login'
    // component: AdminLogin,
  },
  {
    path: '/404',
    name: 'notFound',
    component: notFound,
  },
  {
    path: '/login',
    name: 'login',
    component: login,
  },
  {
    path: '/adminLogin',
    name: 'AdminLogin',
    component: AdminLogin,
    //如果登录的是管理员页面，那么不允许查看用户
    beforeEach: (to, from, next) => {
      console.log(to)
    }
  },
  {
    path: '/forget',
    name: 'forget',
    component: forget,
  },
  {
    path: '/index',
    name: 'index',
    component: index,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: home
      },
      {
        path: '/company',
        name: 'company',
        component: company,
        beforeEnter: (to, from, next) => {
          // console.log(from)
          if (from.path != '/companyDetails') {
            sessionStorage.setItem("currentpageqy", 1);
            sessionStorage.setItem("searchqy", '');
            next()
          } else {
            next()
          }

        }

      },
      {
        path: '/patent',
        name: 'patent',
        component: patent,
        beforeEnter: (to, from, next) => {
          // console.log(from)
          if (from.path != '/patentDetails') {
            sessionStorage.setItem("currentpagezl", 1);
            sessionStorage.setItem("searchzl", '');
            next()
          } else {
            next()
          }

        }
      },
      {
        path: '/history',
        name: 'history',
        component: history
      },
      {
        path: '/follow',
        name: 'follow',
        component: follow
      },
      {
        path: '/patentDetails',
        name: 'patentDetails',
        component: patentDetails
      },
      {

        path: '/companyDetails',
        name: 'companyDetails',
        component: companyDetails

      }
    ]
  },
  //个人信息设置
  {
    path: '/usersetting',
    name: 'usersetting',
    component: usersetting,
    redirect: '/personalSetting',
    children: [
      {
        path: '/personalSetting',
        name: 'personalSetting',
        component: personalSetting,
      },
      {
        path: '/securitySetting',
        name: 'securitySetting',
        component: securitySetting,
      }

    ]
  },
  //管理员设置
  {
    path: '/adminset',
    name: 'adminset',
    component: adminset,
    redirect: '/userInfo',
    children: [
      {
        path: '/userInfo',
        name: 'userInfo',
        component: userInfo,
      },
    ]
  },
  //可视化界面
  {
    path: '/screen',
    name: 'screen',
    component: screen,
  },
  //   {
  //     path: '/404',
  //     name: 'NotFound',
  //     component: () => import('@/views/NotFound/Index.vue')
  // },
  // 所有未定义路由，全部重定向到404页
  {
    path: '/:pathMatch(.*)',
    redirect: '/404'
  }


  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  mode: 'hash',
  routes,

})
router.beforeEach((to, from, next) => {
  if (to.path === '/login' || to.path === '/adminLogin' || to.path === '/forget') {
    next();
  }
  else {
    let token = localStorage.getItem('token');
    if (!token) {
      message.error('请先登录')
      next('/login');
    } else {
      next();
    }
  }

})




export default router
