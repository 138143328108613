<template>
  <div class="home_con">
    <!-- 面包屑导航 -->
    <a-breadcrumb class="nav_bread">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
    </a-breadcrumb>

    <!-- 搜索部分 -->
    <div class="home_search">
      <div class="search_tab">
        <span :class="[tab_id == '1' ? 'patent_active' : 'patent']" @click="changetab('1')">查专利</span>
        <span :class="[tab_id == '1' ? 'company' : 'company_active']" @click="changetab('2')">查企业</span>
      </div>
      <div class="search" v-if="tab_id == 1">
        <a-input-search
          v-model:value="searchValue"
          placeholder="请输入专利标题、专利号、发明人等关键词"
          @search="onSearch"
          @input="onInput(tab_id)"
          @blur="onBlur"
          @focus="onFocus(tab_id)"
        >
          <template #enterButton>
            <img style="padding-right: 5px" src="@/static/home/index/icon-search.png" alt="" />搜索
          </template>
        </a-input-search>
        <div class="search_result" v-show="search_result_show">
          <div class="search_result_item" v-for="(item, index) in search_result" :key="item">
            <div style="position: relative">
              <div
                v-if="patent_cate == 'title'"
                style="cursor: pointer; width: 80%"
                v-html="item.title"
                @click="lenovnoPatentDetails(item)"
              ></div>
              <div
                v-else-if="patent_cate == 'fmr'"
                style="cursor: pointer; width: 80%"
                v-html="item.title"
                @click="lenovnoPatentDetails(item)"
              ></div>
              <div
                v-else
                style="cursor: pointer; width: 80%"
                v-html="item.gkh"
                @click="lenovnoPatentDetails(item)"
              ></div>
              <a-tag class="cate" color="blue" v-if="patent_cate == 'title'">专利名称</a-tag>
              <a-tag class="cate" color="blue" v-else-if="patent_cate == 'fmr'">发明人</a-tag>
              <a-tag class="cate" color="blue" v-else>专利号</a-tag>
            </div>
          </div>
        </div>
      </div>
      <div class="search" v-else>
        <a-input-search
          v-model:value="searchValue"
          placeholder="请输入企业名称、法定代表人等关键词"
          @search="onSearch"
          @input="onInput(tab_id)"
          @blur="onBlur"
          @focus="onFocus(tab_id)"
        >
          <template #enterButton>
            <img style="padding-right: 5px" src="@/static/home/index/icon-search.png" alt="" />搜索
          </template>
        </a-input-search>
        <!-- 联想搜索结果 -->
        <div class="search_result" v-show="search_result_show">
          <div class="search_result_item" v-for="(item, index) in search_result" :key="item">
            <div style="position: relative">
              <div
                v-if="company_cate == 'company_name'"
                style="cursor: pointer; width: 80%"
                v-html="item.company_name"
                @click="lenovnoCompanyDetails(item)"
              ></div>
              <div
                v-else
                style="cursor: pointer; width: 80%"
                v-html="item.company_name"
                @click="lenovnoCompanyDetails(item)"
              ></div>
              <a-tag class="cate" color="blue" v-if="company_cate == 'company_name'">公司名称</a-tag>
              <a-tag class="cate" color="blue" v-else>法人信息匹配</a-tag>
            </div>
          </div>
        </div>
      </div>
      <div class="search_info">
        <div class="search_tips">
          <div class="num">400w+</div>
          <div class="" style="font-family: 'jiangcheng'">企业数量</div>
        </div>
        <div class="split"></div>
        <div class="search_tips">
          <div class="num">200w+</div>
          <div class="" style="font-family: 'jiangcheng'">专利数量</div>
        </div>
        <div class="split"></div>
        <div class="search_tips">
          <div class="num">100w+</div>
          <div class="" style="font-family: 'jiangcheng'">专利授权数量</div>
        </div>
        <div class="split"></div>
        <div class="search_tips">
          <div class="num">11w+</div>
          <div class="" style="font-family: 'jiangcheng'">专利转让数量</div>
        </div>
      </div>
    </div>
    <div class="home_list">
      <!-- 列表 -->
      <!-- 科创能力 -->
      <div class="ranking">
        <div class="ranking_title">
          <img src="@/static/home/index/icon-list01.png" alt="" />
          <span class="title">&nbsp;企业科创能力榜单</span>
          <span class="more" @click="openmore('1')">更多</span>
        </div>
        <!-- 判断是否有数据 -->
        <div v-if="scientificInnovation.length !== 0">
          <div class="rank_item" v-for="item in scientificInnovation" @click="openCompany(item)">
            <div class="doat"></div>
            <div>{{ item.company_name }}</div>
          </div>
        </div>
        <div class="empty_img" v-else>
          <img src="@/static/home/index/img-empty.png" alt="" />
          <div class="nodata">暂无数据</div>
        </div>
      </div>
      <!-- 技术合作 -->
      <div class="ranking">
        <div class="ranking_title">
          <img src="@/static/home/index/icon-list02.png" alt="" />
          <span class="title">&nbsp;企业技术合作榜单</span>
          <span class="more" @click="openmore('2')">更多</span>
        </div>
        <div v-if="innovateList.length !== 0">
          <div class="rank_item" v-for="item in innovateList" @click="openCompany(item)">
            <div class="doat"></div>
            <div>{{ item.company_name }}</div>
          </div>
        </div>
        <div class="empty_img" v-else>
          <img src="@/static/home/index/img-empty.png" alt="" />
          <div class="nodata">暂无数据</div>
        </div>
      </div>
      <div class="ranking">
        <div class="ranking_title">
          <img src="@/static/home/index/icon-list02.png" alt="" />
          <span class="title">&nbsp;浏览记录</span>
          <span class="more" @click="openHistory">更多</span>
        </div>
        <MenuDivider v-if="historyList.length !== 0">
          <div class="rank_item" v-for="item in historyList" @click="openDetails(item)">
            <div class="doat"></div>
            <div>{{ item.name }}</div>
          </div>
        </MenuDivider>
        <div class="empty_img" v-else>
          <img src="@/static/home/index/img-empty.png" alt="" />
          <div class="nodata">暂无浏览记录</div>
        </div>
      </div>
      <div class="ranking">
        <div class="ranking_title">
          <img src="@/static/home/index/icon-list02.png" alt="" />
          <span class="title">&nbsp;我的关注</span>
          <span class="more" @click="openFollow">更多</span>
        </div>
        <div v-if="followList.length !== 0">
          <div class="rank_item" v-for="item in followList" @click="openDetails(item)">
            <div class="doat"></div>
            <div>{{ item.name }}</div>
          </div>
        </div>
        <div class="empty_img" v-else>
          <img src="@/static/home/index/img-empty.png" alt="" />
          <div class="nodata">暂无关注数据</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { followList, userBrowse } from "../../utils/userAPI";
import { technicalCooperation, scientificEvaluationTop, enterpriseLenovo, patentLenovo } from "../../utils/indexApi";

export default {
  name: "home",
  data() {
    return {
      tab_id: "1",
      timer: null,
      patent_cate: "",
      company_cate: "",
      search_result_show: false,
      searchValue: "",
      followList: [],
      historyList: [],
      innovateList: [],
      scientificInnovation: [],
      search_result: [],
    };
  },
  components: {},
  methods: {
    //页面初始化
    init() {
      this.getFollow();
      this.getHistory();
      this.getTechnicalCooperation();
      this.getScientificEvaluationTop();
    },
    getFollow() {
      followList({
        page: 1,
        size: 10,
      }).then((res) => {
        if (res.code == 0) {
          this.followList = res.data;
        } else {
          message.error(res.msg);
        }
      });
    },
    getHistory() {
      userBrowse({
        page: 1,
        size: 10,
      }).then((res) => {
        if (res.code == 0) {
          this.historyList = res.data.data;
        } else {
          message.error(res.msg);
        }
      });
    },
    //获取技术合作榜单
    getTechnicalCooperation() {
      technicalCooperation().then((res) => {
        if (res.code == 0) {
          this.innovateList = res.data;
        }
      });
    },
    //获取科创能能力榜单
    getScientificEvaluationTop() {
      scientificEvaluationTop().then((res) => {
        if (res.code == 0) {
          this.scientificInnovation = res.data;
        }
      });
    },
    //打开历史记录
    openHistory() {
      this.$router.push({
        path: "/history",
      });
    },
    //打开我的关注
    openFollow() {
      this.$router.push({
        path: "/follow",
      });
    },
    //打开企业详情或者专利
    openDetails(item) {
      if (item.f_type == "patent") {
        this.$router.push({
          path: "/patentDetails",
          query: {
            id: item.ent_or_pat_id,
            // zlcode: record.gkh,
            zlcode: item.name,
          },
        });
      } else {
        this.$router.push({
          path: "/companyDetails",
          query: {
            id: item.ent_or_pat_id,
            name: item.name,
          },
        });
      }
    },
    //从联想结果打开专利页面
    lenovnoPatentDetails(item) {
      clearTimeout(this.timer);
      this.$router.push({
        path: "/patentDetails",
        query: {
          id: item.mysql_id,
        },
      });
    },
    //从联想结果打开公司页面
    lenovnoCompanyDetails(item) {
      clearTimeout(this.timer);
      this.$router.push({
        path: "/companyDetails",
        query: {
          id: item.mysql_id,
        },
      });
    },
    //打开企业技术/科创详情页面
    openCompany(item) {
      this.$router.push({
        path: "/companyDetails",
        query: {
          id: item.id,
          name: item.company_name,
        },
      });
    },
    //打开榜单
    openmore(id) {
      if (id == "1") {
        this.$router.push({
          path: "/company",
          query: {
            valueInnovation: "企业科创能力",
          },
        });
      } else {
        this.$router.push({
          path: "/company",
          query: {
            valueInnovation: "企业技术合作",
          },
        });
      }
    },
    changetab(id) {
      // console.log(id)
      this.tab_id = id;
      this.search_result_show = false;
      this.search_result = [];
      this.searchValue = "";
    },
    onSearch() {
      if (this.tab_id == "1") {
        this.$router.push({
          path: "/patent",
          query: {
            keyword: this.searchValue,
          },
        });
      } else {
        this.$router.push({
          path: "/company",
          query: {
            keyword: this.searchValue,
          },
        });
      }
    },
    //联想搜索
    onInput(tab_id) {
      //专利联想搜索
      if (tab_id == 1 && this.searchValue) {
        patentLenovo({ keyword: this.searchValue }).then((res) => {
          if (res.code == 0) {
            this.search_result_show = true;
            this.patent_cate = res.data.title_or_gkh;
            this.search_result = res.data.list;
            if (this.search_result.length == 0) {
              this.search_result_show = false;
            }
          } else {
            this.search_result_show = false;
          }
        });
        // }
      } else if (tab_id == 2 && this.searchValue) {
        this.search_result_show = true;
        enterpriseLenovo({ keyword: this.searchValue }).then((res) => {
          if (res.code == 0) {
            this.search_result_show = true;
            this.company_cate = res.data.name_or_legal;
            this.search_result = res.data.list;
            if (this.search_result.length == 0) {
              this.search_result_show = false;
            }
          } else {
            this.search_result_show = false;
          }
        });
      }
    },
    onFocus(tab_id) {
      if (tab_id == 1 && this.searchValue) {
        patentLenovo({ keyword: this.searchValue }).then((res) => {
          if (res.code == 0) {
            this.search_result_show = true;
            this.patent_cate = res.data.title_or_gkh;
            this.search_result = res.data.list;
            if (this.search_result.length == 0) {
              this.search_result_show = false;
            }
          } else {
            this.search_result_show = false;
          }
        });
        // }
      } else if (tab_id == 2 && this.searchValue) {
        this.search_result_show = true;
        enterpriseLenovo({ keyword: this.searchValue }).then((res) => {
          if (res.code == 0) {
            this.search_result_show = true;
            this.company_cate = res.data.name_or_legal;
            this.search_result = res.data.list;
            if (this.search_result.length == 0) {
              this.search_result_show = false;
            }
          } else {
            this.search_result_show = false;
          }
        });
      }
    },
    onBlur() {
      this.timer = setTimeout(() => {
        this.search_result_show = false;
      }, 150);
    },
  },
  mounted() {},
  created() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
.home {
  height: calc(100% - 65px);
  .home_main {
    // height: 100%;
    // width: 100%;
    background-color: #ebebeb;
    display: flex;
    .home_con {
      background-color: #ebebeb;
      padding-left: 30px;
      width: calc(100% - 80px);
      flex: 1;
      box-sizing: border-box;
      .nav_bread {
        height: 50px;
        padding: 20px 3px;
        background-color: #ebebeb;
      }
      .home_search {
        // width: 1820px;
        width: 1777px;
        height: 308px;
        position: relative;
        background: url("~@/static/home/index/img-index01.png") no-repeat;
        background-size: 100% 100%;
        background-attachment: local;
        .search_tab {
          position: absolute;
          top: 50px;
          left: 50px;

          span {
            //  color: #fff;
            font-family: "jiangcheng";
            display: inline-block;
            width: 140px;
            height: 54px;
            // border-radius: 27px;
            //  background-color: #4079ff;
            text-align: center;
            line-height: 54px;
            font-size: 18px;
            margin-right: 10px;
          }
          .patent {
            background: url("../../static/home/index/icon-czl-normal.png") no-repeat;
            background-size: 100% 100%;
            cursor: pointer;
          }
          .patent_active {
            background: url("../../static/home/index/icon-czl-select.png") no-repeat;
            background-size: 100% 100%;
            color: #fff;
            cursor: pointer;
          }
          .company {
            background: url("../../static/home/index/icon-cqy-normal.png") no-repeat;
            background-size: 100% 100%;
            cursor: pointer;
          }
          .company_active {
            background: url("../../static/home/index/icon-cqy-select.png") no-repeat;
            background-size: 100% 100%;
            color: #fff;
            cursor: pointer;
          }
        }
        .search {
          position: absolute;
          top: 150px;
          left: 50px;
          width: 700px;
          height: 54px;
          background-color: #4079ff !important;
          :deep(.ant-input-group .ant-input) {
            height: 54px;
            font-size: 16px;
            border: 2px solid #4079ff;
            border-radius: 8px;
          }
          :deep(.ant-input-search-button) {
            height: 54px;
            width: 120px;
            background-color: #4079ff !important;
            color: #fff;
            font-size: 18px;
          }
          .search_result {
            position: absolute;
            width: calc(100% - 120px);
            // height: 300px;
            top: 54px;
            left: 0;
            background-color: #fff;
            z-index: 9;
            box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.05);
            .search_result_item {
              // height: 45px;
              padding: 15px 5px 15px 25px;
              font-family: "SF Pro Text";
              font-size: 16px;
            }
            .search_result_item:hover {
              background-color: #f5fbff;
            }
          }
        }
        .search_info {
          position: absolute;
          bottom: 30px;
          left: 50px;
          width: 700px;
          // height: 54px;
          .search_tips {
            width: 24%;
            display: inline-block;
            text-align: center;
            .num {
              color: #4079ff;
              font-family: "jiangcheng";
              font-size: 24px;
            }
          }
          .split {
            width: 2px;
            display: inline-block;
            height: 25px;
            background-color: #ccc;
          }
        }
      }
      .home_list {
        padding: 20px;
        display: flex;
        justify-content: space-evenly;
        background-color: #fff;
        .ranking {
          width: 420px;
          height: 487px;
          border-radius: 4px;
          border: 1px solid #e5e5e5;
          padding: 25px 30px;
          .empty_img {
            text-align: center;
            margin: 50% 0;
            transform: translateY(-50%);
            .nodata {
              color: #bbbbbb;
              font-size: 20px;
            }
          }

          .title {
            font-size: 20px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #1c1c28;
            line-height: 28px;
          }
          .more {
            float: right;
            color: #bbbbbb;
            cursor: pointer;
          }
          .rank_item {
            height: 40px;
            line-height: 40px;
            color: #555770;
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            // position: relative;
            display: flex;
            align-items: center;
            .doat {
              width: 5px;
              height: 5px;
              margin: 10px;

              background-color: #ccc;
              border-radius: 50%;
              line-height: 40px;
            }
          }
        }
      }
    }
  }
}
:deep(b) {
  color: rgba(191, 36, 40, 1);
}
.cate {
  right: 2%;
  position: absolute;
  top: 10%;
}
</style>
