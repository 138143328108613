<template>
  <div style="background: #f3f3f6">
    <div class="company_con">
      <div class="company_con_left">
        <!-- 联合申请 -->
        <div class="company_con1" id="jointApplication">
          <h2 class="title">联合申请</h2>
          <p>
            在遇到技术创新难题时，企业往往会借助外部机构的研究优势和实力来共同研发解决。在某个技术领域企业的产学研合作次数越多，可以反映企业在该技术领域布局的意愿更强烈。同时，关注产学研合作的主要伙伴，可进一步分析企业在主要布局领域的技术依赖程度和权属纠纷可能性。该企业与其他单位共进行了<span
              class="color"
              >{{ joint.all_nums }}</span
            >次产学研合作。
            <template v-if="!joint.all_nums">
              <a-empty></a-empty>
            </template>
            <template v-else>
              其中，与<span class="color" v-for="(item, index) in joint.max_list" :key="item.max_name">{{
                index == joint.max_list.length - 1 ? item.max_name : item.max_name + "，"
              }}</span
              >最为密切，合作次数为<span class="color">{{ joint.max_list[0].max_value }}</span
              >次，与该企业合作次数最多的申请人如下图所示。
            </template>
          </p>
          <EchartsCon :style="'height:300px;'" :option="option1" :id="'echarts1'" v-if="joint.all_nums"></EchartsCon>
        </div>
        <!-- 专利购买 -->
        <div class="company_con1" id="PatentPurchase">
          <h2 class="title">专利购买</h2>
          <p>
            分析该企业专利购买历史，了解该企业向哪些专利权人购买了专利，以及购买了哪些专利。如果该企业购买专利数量较多，需要关注这些外购专利是否为企业的核心专利，进而分析企业的研发能力。
            <template v-if="patentPurchase.max_list.length > 0">
              该企业向<span class="color" v-for="(item, index) in patentPurchase.max_list" :key="item.max_name">{{
                index == patentPurchase.max_list.length - 1 ? item.max_name : item.max_name + "，"
              }}</span
              >购买专利最多，为<span class="color">{{ patentPurchase.max_list[0].max_value }}</span
              >件。
            </template>
            <template v-else>
              该企业购买专利为<span class="color">0</span>件
              <a-empty></a-empty>
            </template>
          </p>
          <EchartsCon
            :style="'height:300px;'"
            :option="option2"
            :id="'echarts2'"
            v-if="patentPurchase.max_list.length != 0"
          ></EchartsCon>
        </div>
      </div>
      <div class="company_con_anchor">
        <a-anchor @click="handleAnchorClick">
          <a-anchor-link style="font-size: 20px; color: #1c1c28; font-weight: bold" title="技术合作" />
          <a-anchor-link href="#jointApplication" title="联合申请" />
          <a-anchor-link href="#PatentPurchase" title="专利购买" />
        </a-anchor>

        <div id="components-back-top-demo-custom">
          <a-back-top>
            <div class="ant-back-top-inner">
              <img src="@/static/common/icon-top.png" alt="" />
              回到顶部
            </div>
          </a-back-top>
          <!-- <div class="back">回到顶部</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EchartsCon from "../../components/EchartsCon";
export default {
  name: "cooperation",
  data() {
    return {
      option1: null,
      option2: null,
      joint: {},
      company_name: "",
      empty2: false,
    };
  },
  props: {
    jointData: Object,
    patentPurchaseData: Object,
  },
  components: {
    EchartsCon,
  },
  created() {
    this.joint = this.$props.jointData;
    this.patentPurchase = this.$props.patentPurchaseData;
    this.company_name = this.$route.query.name;
  },
  beforeMount() {
    this.init();
  },
  methods: {
    handleAnchorClick(e, link) {
      e.preventDefault();
    },
    init() {
      this.getOptions1();
      this.getOptions2();
    },
    getOptions1() {
      let points = [
        {
          name: this.company_name,
          symbolSize: 40,
          category: 0,
          value: 0,
          x: 0,
          y: 0,
          fixed: true,
        },
      ];
      let links = [];
      //添加数据
      if (this.joint.joint_application.length != 0) {
        this.joint.joint_application.forEach((item, index) => {
          if (index < 9) {
            points.push({
              name: item.name,
              symbolSize: 40,
              category: 1,
              value: item.value,
              x: index % 2 == 1 ? 300 * Math.ceil(index / 2) : -300 * Math.ceil(index / 2),
              y: 80 * (10 - index),
            });
            links.push({
              source: this.company_name,
              target: item.name,
              value: item.value,
            });
          }
        });
      }
      this.option1 = {
        legend: {
          show: true,
          data: ["搜索的企业", "其他单位"],
        },
        tooltip: {
          show: true,
        },
        series: [
          {
            type: "graph",
            layout: "none",
            force: {
              repulsion: 100,
              edgeLength: 30,
            },
            bottom: 0,
            roam: false,
            label: {
              show: true,
              formatter: function (params) {
                if (params.value) {
                  return params.name + "(" + params.value + ")"; // 显示节点的数值
                } else {
                  return params.name;
                }
              },
            },
            data: points,
            links: links,
            lineStyle: {
              normal: {
                opacity: 0.9,
                width: 5,
                curveness: 0,
              },
            },
            categories: [{ name: "搜索的企业" }, { name: "其他单位" }],
          },
        ],
      };
    },
    getOptions2() {
      const dataList = this.patentPurchase.patent_purchase;
      if (dataList.length == 0) {
        this.empty2 = true;
      }
      this.option2 = {
        tooltip: {
          show: false,
        },
        grid: {
          top: "5%",
          left: "3%",
          right: "2%",
          bottom: "1%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          axisLabel: {
            color: "#333",
          },
          splitLine: {
            show: true,
            lineStyle: {},
          },
        },
        yAxis: {
          type: "category",
          axisLabel: {
            color: "#333",
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          data: dataList.map((r) => r.name),
        },
        series: [
          {
            name: "专利购买",
            type: "bar",
            //  symbol: "path://M1 1,L140 1,L140 15,L1 15,Z",
            //  symbolKeepAspect: false,
            //  stack: "triangle",
            barWidth: 16,
            label: {
              show: true,
              position: "right",
              // color: "#3EFFE8",
              fontSize: 12,
              formatter: "{c}",
            },
            data: dataList.map((r) => r.value),
          },
        ],
      };
    },
  },
};
</script>

<style lang="less" scoped>
.company_con {
  clear: both;
  position: relative;
  display: flex;
  justify-content: flex-start;
  background-color: #f3f3f6;
  width: 1240px;
  .color {
    color: #53a7f0;
  }
  .company_con_left {
    .company_con1 {
      // width: 1400px;
      // height: 175px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      padding: 40px 30px;
      margin: 15px 0;
      .title {
        color: #1c1c28;
        font-size: 18px;
        font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
        font-weight: bold;

        line-height: 28px;
      }
      .desc {
        font-size: 12px;
        font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
        font-weight: 400;
        color: #1c1c28;
        line-height: 28px;
      }
      .ecahrsThree {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }
    :deep(.ant-table-thead > tr > th) {
      background-color: #f2f9fc;
    }
  }
  .company_con_anchor {
    margin-top: 15px;
    font-weight: bold;
    color: #1c1c28;
    :deep(.ant-anchor-link-title) {
      color: #1c1c28;
      margin-bottom: 15px;
    }
    :deep(.ant-anchor-link-active > .ant-anchor-link-title) {
      color: #1890ff;
    }
    :deep(.ant-anchor-ink::before) {
      width: 8px;
      background-color: #f5f5f5;
    }

    :deep(.ant-anchor-wrapper) {
      background-color: #fff;
      padding-left: 10px;
      color: #1c1c28;
      height: 350px;
      margin-left: 10px;
      padding: 30px 15px 0;
    }
    #components-back-top-demo-custom .ant-back-top {
      // bottom: 50%;
      // right: 18.5%;
      bottom: 55%;
      right: 150px;
    }
    #components-back-top-demo-custom .ant-back-top-inner {
      margin-top: 20px;
      width: 150px;
      height: 60px;
      background-color: #fff;
      text-align: center;
      line-height: 60px;
      border-radius: 8px;
    }
  }
  .back {
    display: none;
    margin-top: 20px;
    width: 150px;
    height: 60px;
    background-color: #fff;
    text-align: center;
    line-height: 60px;
  }
}
</style>
