<template>
  <div class="login">
    <header>
      <div class="header_left">
        <img src="../../static/login/logo-tylgd.png" alt="" />
        <span>太原理工大学专利成果转移转化智能推荐系统</span>
      </div>
      <div class="header_right">
        <span class="email">邮 箱：zysfsyp_1001@163.com</span>
        <span class="tel">热 线： 151-0110-6969</span>
      </div>
    </header>
    <div class="con">
      <div class="con_left">
        <div class="title1">欢迎登录</div>
        <div class="title2">太原理工大学专利成果转移转化智能推荐系统</div>
        <div class="con_left_img">
          <img src="../../static/login/img-login.png" alt="" />
        </div>
      </div>
      <div class="con_right">
        <div class="login_title">
          <span>管理员登录</span>
        </div>
        <a-form
          :model="formState"
          name="normal_login"
          class="login-form"
          @finish="onFinish"
          @finishFailed="onFinishFailed"
        >
          <a-form-item name="username" :rules="[{ required: true, message: '请输入用户名！' }]">
            <a-input v-model:value="formState.username">
              <template #prefix>
                <img src="../../static/login/icon-user.png" alt="" />
              </template>
            </a-input>
          </a-form-item>

          <a-form-item name="password" :rules="[{ required: true, message: '请输入密码！' }]">
            <a-input-password v-model:value="formState.password">
              <template #prefix>
                <img src="../../static/login/icon-password.png" alt="" />
              </template>
            </a-input-password>
          </a-form-item>

          <a-form-item>
            <a-form-item name="remember">
              <a-checkbox v-model:checked="formState.remember">记住密码</a-checkbox>
            </a-form-item>
            <!-- <a-form-item name="remember"  style="float: right;">
              <a-checkbox v-model:checked="formState.autologin"
                >自动登录</a-checkbox
              >
            </a-form-item> -->
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" class="login-form-button" @click="login"> 登录 </a-button>
          </a-form-item>
          <a-form-item>
            <div class="admin_login">
              <img src="../../static/login/icon-glydl.png" alt="" />&nbsp;&nbsp;
              <router-link to="/login">账户登录</router-link>
            </div>
            <!-- <div style="float: right;">
              <a href="">忘记密码</a>
            </div> -->
          </a-form-item>
        </a-form>
      </div>
    </div>
    <footer>
      <!-- <a style="color: #818a9f" href="http://zhongyanrenzhi.com/#/product2" target="_blank"
        >产品介绍&nbsp;&nbsp;&nbsp;&nbsp;</a
      >|
      <a style="color: #818a9f" target="_blank" href="http://zhongyanrenzhi.com/#/into/1"
        >&nbsp;&nbsp;&nbsp;&nbsp;关于中研认知</a
      > -->
      <span>主办：太原理工技术转移有限公司 </span>
      <br />
      <span>技术支持：北京中研硕福科技有限公司&nbsp;&nbsp;&nbsp;&nbsp;</span>丨<span
        >&nbsp;&nbsp;&nbsp;&nbsp;京ICP备19055798号-3
      </span>
    </footer>
  </div>
</template>
<script>
import { AES_Encrypt, AES_Decrypt } from "@/utils/aes_util.js";
import { login } from "@/utils/loginAPI";
import { message } from "ant-design-vue";
export default {
  name: "AdminLogin",
  data() {
    return {
      formState: {
        username: "",
        password: "",
        remember: false,
      },
    };
  },
  methods: {
    //账号登录
    login() {
      let param = AES_Encrypt(
        JSON.stringify({
          login_name: this.formState.username,
          password: this.formState.password,
        })
      );
      login({
        param,
      }).then((res) => {
        // console.log(res);
        if (res.code == 0) {
          if (res.data.is_admin) {
            message.success("登录成功");

            localStorage.setItem("login_name", this.formState.username);
            localStorage.setItem("token", res.data.token);
            this.$router.push({
              path: "/adminset",
            });
          } else {
            message.warning("请切换账号登录页面");
          }
        } else {
          message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.ant-input-affix-wrapper {
  height: 50px;
}
/deep/.ant-form {
  margin-top: 30px;
}
/deep/.login-form-button {
  width: 100%;
  height: 50px;
  font-weight: bold;
  font-size: 18px;
}
/deep/.ant-form-item-control-input {
  height: 60px;
}
.login {
  background-color: #fafbfe;
  height: 100%;
  background-image: url("../../static/login/img-bg.png");
  header {
    height: 65px;
    background-color: #fff;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    .header_left {
      padding: 10px 29px;
      span {
        color: #002d70;
        font-family: "jiangcheng";
        padding: 0 15px;
      }
    }
    .header_right {
      padding: 0 300px 30px;
      .email {
        padding: 0 15px;
        border-left: #5271e9 8px solid;
        color: #002d70;
        font-family: "jiangcheng";
        line-height: 65px;
      }
      .tel {
        padding: 0 15px;
        color: #002d70;
        font-family: "jiangcheng";
        line-height: 65px;
      }
    }
  }
  .con {
    height: calc(100vh - 160px);
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    width: 1560px;
    .con_left {
      // width: 40%;
      // margin-right: -20%;
      .title1 {
        color: #4079ff;
        font-family: "jiangcheng";
        font-size: 34px;
        margin-bottom: 10px;
      }
      .title2 {
        color: #1d2129;
        font-family: "jiangcheng";
        font-size: 32px;
        margin-bottom: 70px;
      }
      .con_left_img {
        img {
          width: 660px;
        }
      }
    }
    .con_right {
      width: 620px;
      height: 600px;
      border-radius: 8px;
      background-color: #fff;
      box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.1);
      padding: 50px 60px;
      .login_title {
        color: #4079ff;
        font-family: Microsoft YaHei UI-Bold;
        font-weight: bold;
        line-height: 60px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        span {
          font-size: 20px;
          padding-bottom: 20px;
          border-bottom: 3px solid rgba(82, 113, 233, 1);
        }
      }
    }
    .admin_login {
      img {
        width: 20px;
        vertical-align: middle;
      }
    }
  }
  footer {
    text-align: center;
    color: #818a9f;
  }
}
</style>
