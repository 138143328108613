import { get, post, put, Delete } from './request'
export const uploadFile = p => post('/api/common/uploadFile', p);
export const userInfo = p => get('/api/users/userInfo', {});
export const bduserInfo = p => post('/api/users/userInfo', p);
export const updateuserInfo = p => put('/api/users/userInfo', p);
//关注企业或者专利
export const userFollow = p => post('/api/users/userFollow', p);
//取消关注
export const cancelFollow = p => Delete('/api/users/userFollow', p);
//关注列表
export const followList = p => get('/api/users/userFollow?page='+p.page+'&size='+p.size);
//浏览记录
export const userBrowse = p => get('/api/users/userBrowse?page='+p.page+'&size='+p.size);
//用户导出记录
export const ExportList = p => get(' /api/users/enterprisePatentExport?page='+p.page+'&size='+p.size);
//导出记录里的发送邮箱
export const sendEmail = p => post('/api/common/sendEmail', p);








