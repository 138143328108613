<template>
  <a-result
    status="404"
    title="404"
    sub-title="抱歉,页面不存在！"
    class="result"
  >
    <template #extra>
      <a-button type="primary" @click="goHome">返回首页</a-button>
    </template>
  </a-result>
</template>

<script>
export default {
  name: "NotFound",
  methods:{
    goHome(){
      this.$router.push({
        path:'/home'
      })
    }
  }
};
</script>
<style lang="less" scoped>
.result{
  height: 900px;
}
:deep(.ant-result-image){
  height: auto;
  
}
</style>
